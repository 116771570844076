import React from 'react';
import axios from 'axios';
import { CustomerConfirmation } from './CustomerConfirmation';
import { rollbar } from '../Rollbar.js'

export class OrderConfirmation extends React.Component {
    constructor(props) {
        super(props);

        var invoiceDateOptions = [];

        for (var i = 1; i <= 28; i++) {
            var lastDigit = i.toString().slice(-1);
            if (lastDigit === "1" && (i < 10 || i > 20)) {
                invoiceDateOptions.push({ value: i, name: i + "st" });
            }
            else if (lastDigit === "2" && (i < 10 || i > 20)) {
                invoiceDateOptions.push({ value: i, name: i + "nd" });
            }
            else if (lastDigit === "3" && (i < 10 || i > 20)) {
                invoiceDateOptions.push({ value: i, name: i + "rd" });
            }
            else {
                invoiceDateOptions.push({ value: i, name: i + "th" });
            }
        }

        this.state = {
            loading: true,
            orderSummary: null,
            error: false,
            redirectFlowId: null,
            timeRemaining: null,
            timerId: null,
            invoiceDate: 1,
            invoiceDateOptions: invoiceDateOptions,
            complete: false
        };

        this.tick = this.tick.bind(this);
        this.msToMinutesAndSeconds = this.msToMinutesAndSeconds.bind(this);

        this.handleInvoiceDateChange = this.handleInvoiceDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleHomeClick = this.handleHomeClick.bind(this);
    }

    handleInvoiceDateChange(e) {
        const newInvoiceDate = parseInt(e.target.value);
        this.setState({ invoiceDate: newInvoiceDate });
    }

    handleSubmit(error) {
        if (error === false) {
            this.setState({ complete: true });

            function generateNonce() {
                let array = new Uint32Array(1);
                window.crypto.getRandomValues(array);
                return array[0];
            }
            
            let nonce = generateNonce();

            var contractValue = Number((this.state.orderSummary.service_cost_inc_vat * this.state.orderSummary.minimum_service_duration_in_months) / 1.2);
            var routerCharge = Number(this.state.orderSummary.router_cost_inc_vat / 1.2);
            var connectionCharge = Number(this.state.orderSummary.connection_cost_inc_vat / 1.2);
            var total = Number(contractValue + routerCharge + connectionCharge);

            if (process.env.NODE_ENV === "production") {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-754962152/G_FiCOCe4ckZEOid_-cC',
                    'transaction_id': nonce.toString()
                });
                window.gtag('event', 'purchase', {
                    transaction_id: nonce.toString(),
                    value: Number(total),
                    tax: Number(total * 0.2),
                    currency: 'GBP',
                    coupon: this.state.orderSummary.voucher_code,
                    items: [
                    {
                        item_id: '',
                        item_name: this.state.orderSummary.service_description,
                        price: Number(contractValue)
                    },
                    {
                        item_id: '',
                        item_name: this.state.orderSummary.router_description === 'Customer to supply' ? 'Customer to supply router' : this.state.orderSummary.router_description,
                        price: Number(routerCharge)
                    },
                    {
                        item_id: '',
                        item_name: 'Connection charge',
                        price: Number(connectionCharge)
                    }]
                });
            }
            else {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-754962152/G_FiCOCe4ckZEOid_-cC',
                    'transaction_id': nonce.toString(),
                    'debug_mode': true
                });
                window.gtag('event', 'purchase', {
                    transaction_id: nonce.toString(),
                    value: Number(total),
                    tax: Number(total * 0.2),
                    currency: 'GBP',
                    coupon: this.state.orderSummary.voucher_code,
                    items: [
                    {
                        item_id: '',
                        item_name: this.state.orderSummary.service_description,
                        price: Number(contractValue)
                    },
                    {
                        item_id: '',
                        item_name: this.state.orderSummary.router_description === 'Customer to supply' ? 'Customer to supply router' : this.state.orderSummary.router_description,
                        price: Number(routerCharge)
                    },
                    {
                        item_id: '',
                        item_name: 'Connection charge',
                        price: Number(connectionCharge)
                    }],
                    'debug_mode': true
                });
            }
        }
        else {
            this.setState({ error: true });
        }
    }

    handleHomeClick(e) {
        window.location.href = "https://1310.io/";
    }
    
    async componentDidMount() {
        if (process.env.NODE_ENV === "production") {
            window.gtag('event', 'gocardless_callback');
        }
        else {
            window.gtag('event', 'gocardless_callback', { 'debug_mode':true });
        }
        const queryString = require('query-string');
        const redirect_flow_id = queryString.parse(window.location.search).redirect_flow_id;

        this.setState({ redirectFlowId: redirect_flow_id });

        await axios.get(process.env.REACT_APP_THIRTEEN_TEN_API + '/confirmation/summary?redirectFlowId=' + redirect_flow_id)
        .then(response => {
            console.log(response.data);
            this.setState({ orderSummary: response.data }, function() {
                this.timerID = setInterval(
                    () => this.tick(),
                    1000
                  );
            });
        })
        .catch(error => {
            rollbar.error(error);
            this.setState({ error: true });
        })
        .finally(() => {
            this.setState({ loading: false });
        });
    }

    async componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        var expiry = new Date(this.state.orderSummary.expiry_time);
        var now = new Date();
        var remaining = expiry - now;
        if (remaining > 0) {
            this.setState({ timeRemaining: this.msToMinutesAndSeconds(remaining) });
        }
        else {
            window.location.href = '/';
        }
    }

    msToMinutesAndSeconds(ms) {
        var minutes = Math.floor(ms / 60000);
        var seconds = ((ms % 60000) / 1000).toFixed(0);
        if (minutes < 10) {
            return (
                "Time remaining: " + (seconds === 60 ? (minutes+1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds)
            );
        }
        return null;
    }

    render() {
        return (
            <div className="App m-5">
                <div className="columns is-multiline is-mobile is-centered">
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        {
                            this.state.loading &&
                            <div className="fa-2x">
                                <i className="fas fa-spinner fa-pulse"></i>
                            </div>
                        }
                        {
                            this.state.error && 
                            <div className="block">
                                <p>Sorry, an error occurred while confirming your order details. Please <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a> or call us on <a href="tel:03333001310">0333 300 1310</a> to discuss.</p>
                            </div>
                        }
                        {
                            !this.state.loading && !this.state.error && !this.state.complete &&
                            <div className="block">
                                { this.state.timeRemaining && <h2 className="title is-6"><strong>{this.state.timeRemaining}</strong></h2> }
                                <div className="box">
                                    <p>
                                        Please note: Our payment provider partner, GoCardless, requires the sign-up process to be completed within 25 minutes. After this time, you will be redirected back to the beginning of the process.
                                    </p>
                                </div>
                                <div id="customer-confirmation" className="card selected">
                                    <div className="card-header">
                                        <div className="card-header-title is-centered">
                                            <h2 className="subtitle is-5">Order Summary</h2>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <p className="block">
                                            {this.state.orderSummary.name}
                                            <br />{this.state.orderSummary.street_1}, {this.state.orderSummary.city}, {this.state.orderSummary.zip_code}
                                            <br />
                                            {this.state.orderSummary.email}
                                            <br />
                                            {this.state.orderSummary.phone}
                                        </p>
                                        <p className="block">
                                            Selected Product: {this.state.orderSummary.service_description}
                                            <br />
                                            {
                                                this.state.orderSummary.initial_service_cost_inc_vat !== null &&
                                                this.state.orderSummary.initial_service_cost_duration_in_months !== null &&
                                                (
                                                    "£" + (this.state.orderSummary.initial_service_cost_inc_vat.toFixed(2) + " per month for the first " 
                                                    + (this.state.orderSummary.initial_service_cost_duration_in_months > 1 
                                                        ? this.state.orderSummary.initial_service_cost_duration_in_months + " months, then £" 
                                                        : "month, then £")) + this.state.orderSummary.service_cost_inc_vat.toFixed(2) + " per month."
                                                )
                                            }
                                            {
                                                this.state.orderSummary.initial_service_cost_inc_vat !== null &&
                                                this.state.orderSummary.initial_service_cost_duration_in_months == null
                                                && "£" + this.state.orderSummary.initial_service_cost_inc_vat.toFixed(2) + " per month."
                                            }
                                            {
                                                this.state.orderSummary.initial_service_cost_inc_vat == null &&
                                                this.state.orderSummary.initial_service_cost_duration_in_months == null
                                                && "£" + this.state.orderSummary.service_cost_inc_vat.toFixed(2) + " per month."
                                            }
                                            <br />
                                            {this.state.orderSummary.minimum_service_duration_in_months} month minimum contract.
                                        </p>
                                        <p className="block">
                                            <span className="is-inline-block m-2">Monthly invoice date:</span><br />
                                            <span className="select">
                                                <select onChange={this.handleInvoiceDateChange}>
                                                {
                                                    this.state.invoiceDateOptions.map((e) => {
                                                        return <option key={e.value} value={e.value}>{e.name}</option>;
                                                    })
                                                }
                                                </select>
                                            </span>
                                            <span className="is-inline-block m-2">of the month.</span>
                                            <br />
                                            <span className="is-inline-block m-2">The first invoice will be raised following your installation. This first invoice will cover the period between your installation and the invoice date, as well as the upcoming month in advance.</span>
                                        </p>
                                        <p className="block">
                                            {
                                                <span>
                                                    Connection Charge: £{this.state.orderSummary.connection_cost_inc_vat.toFixed(2)}
                                                    <br /><br />
                                                </span>
                                            }
                                            {
                                                this.state.orderSummary.router_description !== '' &&
                                                    <span>
                                                        Router: {this.state.orderSummary.router_description}
                                                        <br/>
                                                        Router Charge: £{this.state.orderSummary.router_cost_inc_vat.toFixed(2)}
                                                        <br />
                                                    </span>
                                            }
                                        </p>
                                        {
                                            (
                                                this.state.orderSummary.connection_cost_inc_vat > 0 
                                                || this.state.orderSummary.router_cost_inc_vat > 0
                                            )
                                            && this.state.orderSummary.preorder &&
                                            <p className="block">Connection &amp; router charges (if applicable) will be debited once we have contacted you to book your order in for installation.</p>
                                        }
                                        {
                                            (
                                                this.state.orderSummary.connection_cost_inc_vat > 0 
                                                || this.state.orderSummary.router_cost_inc_vat > 0
                                            )
                                            && !this.state.orderSummary.preorder &&
                                            <p className="block">Connection &amp; router charges (if applicable) will be debited within 48 hours.</p>
                                        }
                                        {
                                            this.state.orderSummary.referral_credit > 0 &&
                                            <p className="block">Referral reward:<br />£{this.state.orderSummary.referral_credit.toFixed(2)} account credit for you and your referrer.</p>
                                        }
                                        <h2 className="title is-6">All prices include VAT</h2>
                                    </div>
                                </div>
                                <CustomerConfirmation redirectFlowId={this.state.redirectFlowId} invoiceDate={this.state.invoiceDate} 
                                    onSubmit={this.handleSubmit} orderSummary={this.state.orderSummary} />
                            </div>
                        }
                        {
                            this.state.complete &&
                            <div id="system-confirmation" className="card selected">
                                <div className="card-header">
                                    <div className="card-header-title is-centered">
                                        <h2 className="subtitle is-5">Order Confirmation</h2>
                                    </div>
                                </div>
                                <div className="card-content">
                                    <h1 className="title is-4 mt-3">Thank you for your order</h1>
                                    <p className="m-5">We will be in touch soon to confirm your order details.</p>
                                    <button className="button is-primary mb-3" onClick={this.handleHomeClick}>Home</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}