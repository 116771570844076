import React from 'react';
import RouterHelper from "./RouterHelper";

export class AvailableRouters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRouterId: -1
        };
        
        this.handleClick = this.handleClick.bind(this);
    }

    async componentDidMount() {
        document.getElementById("routerSelection")?.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }

    handleClick(e) {
        var newSelectedRouterId = parseInt(e.target.closest(".card")?.id);

        const selectedRouter = this.props.availableRouters.find( ({ id }) => parseInt(id) === newSelectedRouterId );
        
        if (newSelectedRouterId === 0 || selectedRouter.available) {
            this.setState({ selectedRouterId: newSelectedRouterId }, function() {
                this.props.onClick(this.state.selectedRouterId);
            });
        }
    }

    render() {
        return (
            <div id="routerSelection">
                <h1 className="title is-3">Router Selection</h1>
                <p className="block">
                    To make use of an internet service you will require a router for your house. This will also usually provide you with access to WiFi.
                    <br />
                    As standard we like to provide a "wires only" service which means the customer is free to supply their own choice of router.
                    <br />
                    If however you would like us to supply a router for you please make your selection from our available stock below.
                </p>
                <h2 className="title is-6">All prices include VAT</h2>
                <div className="columns is-multiline is-mobile is-centered">
                    { this.props.availableRouters.map(router => (
                        <div key={router.id} className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                            <div className={`router mb-3 card ${!router.available ? " unavailable" : "hover"}`}
                                id={router.id} onClick={this.handleClick}>
                                <div className="card-header">
                                    <div className="card-header-title is-centered">
                                        <h2 className="subtitle is-5">{router.name}</h2>
                                    </div>
                                </div>
                                <div className="card-content product-description">
                                    { router.description && <p className="pre mb-5">{router.description}</p>}
                                    <p>£{router.price.toFixed(2)}</p>
                                    {RouterHelper.getRouterDiscountInfo(router, this.props.routerDiscount, this.props.discountExpiry, this.props.discountCode)}
                                    <div className="field mt-5">
                                        <div className="control">
                                            <button className="button is-primary" onClick={this.handleClick}
                                            disabled={!router.available}>{`${!router.available ? " Out of stock" : "Select"}`}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="column is-full-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                        <div className="router card hover" id="0" onClick={this.handleClick}>
                            <div className="card-header">
                                <div className="card-header-title is-centered">
                                    <h2 className="subtitle is-5">Wires Only</h2>
                                </div>
                            </div>
                            <div className="card-content product-description">
                                <p className='mb-5'>I will supply my own router</p>
                                <div className="field">
                                    <div className="control">
                                        <button className="button is-primary" onClick={this.handleClick}>Select</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}